/**
 * This is place for storing constants for trackers
 */

export const systemName = 'web';

export const providerNames = {
  SENTRY: 'SENTRY',
  POSTHOG: 'POSTHOG',
  CIO: 'CIO',
  FACEBOOK_PIXEL: 'FB',
  GTAG: 'GTAG',
};

export const sessionEventName = {
  NAV_GET_STARTED: 'Login: Click Nav Get Started',
  NAV_LOGIN: 'Login: Click Nav Log In',
  OPEN_MODAL: 'Login: Open',
  AUTH_PROVIDER: 'Login: Choose Auth',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  LOGOUT_DUE_TO_EXPIRED: 'Logout: Returning Incomplete User',
  LOGIN_LINK_SENT_TO_EMAIL: 'Login: Link Sent To Email',
  LOGIN_PIN_SENT_TO_EMAIL: 'Login: Pin Sent To Email',
  LOGIN_ACCESS_REQUEST_WITH_PIN: 'Login: Access Request With Pin',
  SESSION: 'Session: Created',
  SIGNUP: 'Signup: Create Profile',
  TERMS: 'Signup: Accept Terms',
  ADDED_FIRST_NAME: 'Signup: Add First Name',
  ADDED_LAST_NAME: 'Signup: Add Last Name',
  ADDED_PHONE_NUMBER: 'Signup: Add Phone Number',
};

export const findAddressTrackEventName = {
  RESULT: 'Address Search',
  SELECT: 'Address Search: Select',
};

export const findCityTrackEventName = {
  RESULT: 'City Search',
  SELECT: 'City Search: Select',
};

export const manualAddressEventNames = {
  ADDED: 'Manual Address: Added',
  CLICKED: 'Manual Address: Clicked',
};

export const rolePickerEventNames = {
  OPEN: 'Role Picker: Open',
  SELECT_ROLE: 'Role Picker: Select Role',
  SWITCH_ROLE: 'Role Picker: Switch Role',
};

export const navigationEventNames = {
  OPEN_DROPDOWN_MENU: 'Navigation: Open Dropdown Menu',
  CLOSE_DROPDOWN_MENU: 'Navigation: Close Dropdown Menu',
  CLICK_NAVIGATION_ITEM: 'Navigation: Click Menu Item',
};

export const navigationDropdownMenuTypes = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
};

export const activityHubEventNames = {
  OPEN: 'Activity Hub: Open',
  CLOSE: 'Activity Hub: Close',
  CLEAR_ALL: 'Activity Hub: Clear All',
  CLICK_NOTIFICATION: 'Activity Hub: Click Notification',
};

export const feedbackWidgetEventNames = {
  FIRE_TRIGGER: 'Triggers: Fire Feedback Trigger',
  SHOW_BUTTON: 'Feedback Widget: Show Feedback Button',
  OPEN_MODAL: 'Feedback Widget: Open Feedback Modal',
  SUBMIT_MAIN_TOPIC: 'Feedback Widget: Submit Main Topic',
  SUBMIT_FEEDBACK: 'Feedback Widget: Submit Feedback',
};

export const invitationTrackingEventNames = {
  USE_GALLERY: 'Invitation: Use Gallery',
  CLICK_WHY_NO_DEPOSIT: 'Invitation: Click Why No Deposit',
  START_LOGIN: 'Invitation: Start Login',
  FILL_PROFILE: 'Invitation: Add Profile Info',
  SUBMIT_BACKGROUND_CHECK: 'Invitation: Add ID Code',
  APPLY: 'Invitation: Submit Candidacy',
  CANCEL: 'Invitation: Cancel Candidacy',
  VIEW: 'Invitation: View',
  CLICK_RENDIN_FEE_INFO_MODAL: 'Invitation: Click Rendin Fee Info Modal',
};

export const candidatesTrackingEventNames = {
  VIEW_LIST: 'Candidates: View List',
  VIEW_CANDIDATE: 'Candidates: View Candidate',
  ADD_AS_A_TENANT: 'Candidates: Add as a Tenant',
  REMOVE_CANDIDATE: 'Candidates: Remove Candidate',
  COPY_AGREEMENT_WITH_TENANT: 'Candidates: Copy Agreement with Tenant',
};

export const searchTrackingEventNames = {
  SEARCH_LISTINGS: 'Ad Search',
  SUGGESTIONS_SORT: 'Ad Search: Sort',
  SUGGESTIONS_FETCH: 'Ad Search: Fetch Similar',
  SUGGESTIONS_CLICK: 'Ad Search: Click Similar',
};

export const trackingProcessNames = {
  ADVERTISEMENT: 'Advertisement',
  AGREEMENT_DRAFTING: 'Agreement Drafting',
  AGREEMENT: 'Agreement',
  MARKETING_SOCIAL_SHARING_MODAL: 'Marketing: Social Sharing Modal',
};

export const trackingFlowNames = {
  AGREEMENT_FLOW: 'Agreement Flow',
};

export const trackingProperties = {
  EVENT_FLOW_NAME: 'processName',
  OBJECT_TYPE: 'objectType',
  AGREEMENT_ID: 'agreementId',
  AGREEMENT_WORKFLOW: 'agreementWorkflow',
  AGREEMENT_TYPE: 'agreementType',
  AGREEMENT_STATUS: 'agreementStatus',
  AGREEMENT_LANDLORD_ID: 'agreementLandlordId',
  AGREEMENT_CREATOR_ID: 'agreementCreatorId',
  AGREEMENT_TENANT_ID: 'agreementTenantId',
  LOCATION: 'location',
  MODAL_TYPE: 'modalType',
  NETWORK: 'network',
  CHANNEL: 'channel',
  RENEWAL: 'renewal',
};

export const agreementTrackingEventNames = {
  OPEN_NEW: 'Agreement Flow: Open New',
  CREATE_NEW: 'Agreement Flow: Create New',
  DELETE: 'Agreement Flow: Delete',
  OPEN: 'Agreement Flow: Open',

  OPEN_PROPERTY_TAB: 'Agreement Flow: Open Property Tab',
  OPEN_AGREEMENT_DETAILS_TAB: 'Agreement Flow: Open Agreement Details Tab',
  OPEN_PARTIES_TAB: 'Agreement Flow: Open Parties Tab',
  OPEN_SIGNING_INFO: 'Agreement Flow: Open Signing Info Tab',
  OPEN_SIGNING_TAB: 'Agreement Flow: Open Signing Tab',
  OPEN_HANDOVER_TAB: 'Agreement Flow: Open Handover Tab',
  OPEN_AGREEMENT_TAB: 'Agreement Flow: Open Agreement Tab',

  PROPERTY_TAB: 'Property Tab',
  DETAILS_TAB: 'Details Tab',
  PARTIES_TAB: 'Parties Tab',
  SIGNING_TAB: 'Signing Tab',
  HANDOVER_TAB: 'Handover Tab',
  AGREEMENT_TAB: 'Agreement Tab',

  SET_ADDRESS: 'Agreement Flow: Set Address',
  SET_APARTMENT: 'Agreement Flow: Set Apartment',
  SET_ROOMS: 'Agreement Flow: Set Rooms',
  SET_AREA: 'Agreement Flow: Set Area',
  SET_PARKING: 'Agreement Flow: Set Parking',
  SET_STORAGE: 'Agreement Flow: Set Storage',
  SET_PETS_POLICY: 'Agreement Flow: Set Pets Policy',
  SET_PETS_SPECIAL_CONDITIONS: 'Agreement Flow: Set Pets Special Conditions',

  SET_LANGUAGE: 'Agreement Flow: Set Language',
  SET_START_DATE: 'Agreement Flow: Set Start Date',
  SET_HANDOVER_DATE: 'Agreement Flow: Set Handover Date',
  SET_END_DATE: 'Agreement Flow: Set End Date',
  SET_SPECIAL_CONDITIONS: 'Agreement Flow: Set Special Conditions',
  SET_PRICE: 'Agreement Flow: Set Price',
  SET_PAYMENT_DAY: 'Agreement Flow: Set Payment Day',
  SET_ACCOUNT_NBR: 'Agreement Flow: Set Account Nbr',
  SET_ACCOUNT_OWNER: 'Agreement Flow: Set Account Owner',
  SET_WHO_PAYS_RENDIN: 'Agreement Flow: Set Who Pays Rendin',

  OPEN_LANDLORD: 'Agreement Flow: Open Landord',
  SOMEONE_ELSE_IS_LANDLORD: 'Agreement Flow: Someone Else Is Landlord',
  CREATOR_IS_LANDLORD: 'Agreement Flow: Creator Is Landlord',
  CREATOR_PROFILE_INCOMPLETE: 'Agreement Flow: Creator Profile Incomplete',
  CREATOR_PROFILE_UPDATED: 'Agreement Flow: Creator Profile Updated',
  SEARCH_LANDLORD: 'Agreement Flow: Search Landlord',
  ADD_LANDLORD: 'Agreement Flow: Add Landlord',
  REMOVE_LANDLORD: 'Agreement Flow: Remove Landlord',

  OPEN_TENANT: 'Agreement Flow: Open Tenant',
  OPEN_APPLICANTS: 'Agreement Flow: Open Applicants',
  COPY_INVITE_LINK: 'Agreement Flow: Copy Invite Link',
  ADD_TENANT: 'Agreement Flow: Add Tenant',
  REMOVE_TENANT: 'Agreement Flow: Remove Tenant',

  OPEN_CO_TENANT: 'Agreement Flow: Open Co-Tenant',
  SEARCH_CO_TENANT: 'Agreement Flow: Search Co-Tenant',
  ADD_CO_TENANT: 'Agreement Flow: Add Co-Tenant',
  REMOVE_CO_TENANT: 'Agreement Flow: Remove Co-Tenant',

  OPEN_AGREEMENT_TYPE: 'Agreement Flow: Open Agreement Type Tooltip',
  OPEN_PROFILE_IN_AGREEMENT_TYPE:
    'Agreement Flow: Open Profile In Agreement Type Tooltip',
  CREATOR_PROFILE_UPDATED_IN_AGREEMENT_TYPE:
    'Agreement Flow: Creator Profile Updated In Agreement Type Tooltip',
  CREATOR_PROFILE_UPDATED_IN_AGREEMENT_PARTIES:
    'Agreement Flow: Creator Profile Updated In Agreement Parties Landlord Profile Change Modal',

  OPEN_UTILITIES: 'Agreement Flow: Open Utilities',
  ADD_UTILITY: 'Agreement Flow: Add Utility',
  REMOVE_UTILITY: 'Agreement Flow: Remove Utility',
  CHANGE_UTILITY: 'Agreement Flow: Change Utility',
  RESET_UTILITIES: 'Agreement Flow: Reset Utilities',
  EDIT_UTILITY_VIEW: 'Agreement Flow: Open Edit Utility view',

  OPEN_PROPERTY_HANDOVER_DATE_INFO:
    'Agreement Flow: Open Property Handover Date Info Modal',
  OPEN_AGREEMENT_END_DATE_INFO: 'Agreement Flow: Open Agreement End Date Info Modal',

  OPEN_FEES: 'Agreement Flow: Open Fees',
  OPEN_ADMINISTRATIVE_RENT: 'Agreement Flow: Open Administrative Rent',
  ADD_FEE: 'Agreement Flow: Add Fee',
  ADD_ADMINISTRATIVE_RENT: 'Agreement Flow: Add Administrative Rent',
  DELETE_ADMINISTRATIVE_FEE: 'Agreement Flow: Delete Administrative Rent',
  CHANGE_ADMINISTRATIVE_FEE: 'Agreement Flow: Change Administrative Rent',
  REMOVE_FEE: 'Agreement Flow: Remove Fee',

  OPEN_GENERAL_TERMS: 'Agreement Flow: Open General Terms',
  INSPECT_GENERAL_TERMS: 'Agreement Flow: Inspect General Terms',

  OPEN_PREVIEW: 'Agreement Flow: Open Preview',
  READY_TO_SIGN: 'Agreement Flow: Ready To Sign',
  READY_TO_SIGN_VALIDATION_FAILED: 'Agreement Flow: Ready To Sign Validation Failed',

  DOWNLOAD_DOCUMENT: 'Agreement Flow: Download Document',

  OPEN_AGREEMENT_TYPE_CHOICE: 'Agreement Flow: Open Agreement Type Choice',
  CHOOSE_AGREEMENT_TYPE: 'Agreement Flow: Choose Agreement Type',
  CHANGE_AGREEMENT_TYPE: 'Agreement Flow: Change Agreement Type',
  OPEN_AGREEMENT_PROFILE_CHANGE: 'Agreement Flow: Open Agreement Profile Change',

  OPEN_AGREEMENT_SHARING_MODAL: 'Agreement Flow: Open Agreement Sharing Modal',
  OPEN_SORT_AND_FILTER: 'Agreement Flow: Open Sort And Filter',
  CLOSE_SORT_AND_FILTER: 'Agreement Flow: Close Sort And Filter',
  SELECT_FILTER: 'Agreement Flow: Sort',
  SEARCH: 'Agreement Flow: Search',

  OPEN_BACKGROUND_CHECK_INFO: 'Agreement Flow: Open Background Check Info',
  INSPECT_BACKGROUND_CHECK_INFO: 'Agreement Flow: Inspect Background Check Info',

  HANDOVER_CREATED: 'Handover: Handover Created',
  HANDOVER_READY_TO_SEND: 'Handover: Handover Ready To Send',
  HANDOVER_COMPLETED: 'Handover: Handover Completed',
  HANDOVER_FINISHED: 'Handover: Handover Finished',
  HANDOVER_FEEDBACK: 'Handover: Tenant Added Feedback',
  HANDOVER_FILE_SIZE: 'Handover: Image Upload Size Error',

  OPEN_ANNEX: 'Agreement Flow: Open Annex',
  OPEN_CANCEL_ANNEX: 'Agreement Flow: Open Cancel Annex',

  ANNEX_READ_INSTRUCTIONS: 'Agreement Annex Flow: Read Instructions',
  ANNEX_NEW_END_DATE: 'Agreement Annex Flow: Set New End Date',
  ANNEX_ACCEPT_PREVIEW: 'Agreement Annex Flow: Accept Preview',
  ANNEX_READY_TO_SIGN: 'Agreement Annex Flow: Ready To Sign',

  OPEN_TERMINATION: 'Agreement Flow: Open Termination',
  TERMINATION_OPEN_VIEW: 'Agreement Termination Flow: Open View',
  TERMINATION_OPEN_CHANGE_VIEW: 'Agreement Termination Flow: Open Termination Change',
  TERMINATION_START: 'Agreement Termination Flow: Start Termination',
  TERMINATION_OPEN_MUTUAL: 'Agreement Termination Flow: Open Mutual Termination',
  TERMINATION_NEW_END_DATE: 'Agreement Termination Flow: Set New End Date',
  TERMINATION_POST_TERMINATION: 'Agreement Termination Flow: Ready To Sign',
  TERMINATION_OPEN_MODAL: 'Agreement Termination Flow: Open Modal',

  TERMINATION_ADD_DETAILS: 'Agreement Termination Flow: Add details',
  TERMINATION_TERMINATION_REASONS:
    'Agreement Termination Flow: Select Termination Reasons',
  TERMINATION_RESELECT_CONTACT_CS:
    'Agreement Termination Flow: Reselect termination type, Contact CS',
  TERMINATION_RESELECT_MUTUAL:
    'Agreement Termination Flow: Reselect Mutual Termination Type',
};

export const agreementTerminationModals = {
  CANCELING_ACTIVITY_CARD: 'Canceling from activity card',
  CANCELING_TILE: 'Canceling from the tle',
  HELP: 'Question mark',
  IN_SIGNING: 'Termination start from dropdown (in signing termination)',
  AGREEMENT_RENEWAL_TERMINATION_IN_SIGNING:
    'Agreement renewal modal (termination in signing)',
  AGREEMENT_RENEWAL_TERMINATION_ACTIVE: 'Agreement renewal modal (termination signed)',
};

export const liveChatEventNames = {
  OPEN_CHAT: 'Live Chat: Open',
  CLOSE_CHAT: 'Live Chat: Close',
};

export const inviteLinkEventNames = {
  INVITE_LINK_OPEN_SHARE: 'Invite Link: Open Share',
  INVITE_LINK_COPY: 'Invite Link: Copy',
};

export const leadGeneratorWidgetEventNames = {
  OPEN_MODAL: 'Lead Generator Widget: Open Modal',
  SUBMIT: 'Lead Generator Widget: Submit Form',
  CLOSE_MODAL: 'Lead Generator Widget: Close Modal',
};

export const sortingOptionsForTracking = {
  NEWEST_FIRST: 'Newest first',
  OLDEST_FIRST: 'Oldest first',
  UPDATED_FIRST: 'Updated first',
  HIGHER_PRICE_FIRST: 'Price: higher first',
  LOWER_PRICE_FIRST: 'Price: lower first',
  ACTIVE_NEWEST_FIRST: 'Active: newest first',
  ACTIVE_OLDEST_FIRST: 'Active: oldest first',
  DRAFT_NEWEST_FIRST: 'Drafts: newest first',
  DRAFT_OLDEST_FIRST: 'Drafts: oldest first',
  IN_SIGNING_NEWEST_FIRST: 'In signing: newest first',
  IN_SIGNING_OLDEST_FIRST: 'In signing: oldest first',
  NOT_ACTIVE_NEWEST_FIRST: 'Not Active: newest first',
  NOT_ACTIVE_OLDEST_FIRST: 'Not Active: oldest first',
  DISABLED_NEWEST_FIRST: 'Disabled: newest first',
  DISABLED_OLDEST_FIRST: 'Disabled: oldest first',
};

export const searchAndFilterTrackingEventNames = {
  OPEN_SORT_AND_FILTER: 'Advertisement Flow: Open Sort And Filter',
  CLOSE_SORT_AND_FILTER: 'Advertisement Flow: Close Sort And Filter',
  SELECT_FILTER: 'Advertisement Flow: Sort',
  SEARCH: 'Advertisement Flow: Search',
};

export const marketingEventNames = {
  SHARE_MODAL_OPEN: 'Share Modal: Open',
  SHARE_MODAL_COPY_LINK: 'Share Modal: Copy Link',
};

export const leadMagnetFormEventNames = {
  START_FORM: 'Lead Magnet Form: Start Form',
  NEW_LEAD: 'Lead Magnet Form: New Lead',
  NEXT_STEP: 'Lead Magnet Form: Next Step',
  ENTER_DATA: 'Lead Magnet Form: Enter Data',
  LOGIN: 'Lead Magnet Form: Login',
};

export const priceCalculatorFormEventNames = {
  START: 'Price Calculator: Start',
  PROPERTY_TYPE: 'Price Calculator: Choose Property Type',
  PROPERTY_DETAILS: 'Price Calculator: Add Property Details',
  LOADING: 'Price Calculator: Loading',
  RESULTS: 'Price Calculator: Show Results',
  NO_RESULTS: 'Price Calculator: No Results',
};

export const censorshipEventNames = {
  USED: 'Censorship: Forbidden Word Used',
  IGNORED: 'Censorship: Warning Ignored',
  SUCCESS: 'Censorship: Requirements met',
};

export const listingSubscription = {
  OPEN_MODAL: 'Listing Subscription: Open modal',
  SUBSCRIBE: 'Listing Subscription: Subscribe',
  UNSUBSCRIBE: 'Listing Subscription: Unsubscribe',
  SECTION_CLICK: 'Listing Subscription: Section button click',
};
