import resolveConfig from 'tailwindcss/resolveConfig';
import tailwind from '~/tailwind.config.js';

const tailwindConfig = resolveConfig(tailwind);

export const sortingOptions = {
  NEWEST_FIRST: 'NEWEST_FIRST',
  OLDEST_FIRST: 'OLDEST_FIRST',
  UPDATED_FIRST: 'UPDATED_FIRST',
  HIGHER_PRICE_FIRST: 'HIGHER_PRICE_FIRST',
  LOWER_PRICE_FIRST: 'LOWER_PRICE_FIRST',
  ACTIVE_NEWEST_FIRST: 'ACTIVE_NEWEST_FIRST',
  ACTIVE_OLDEST_FIRST: 'ACTIVE_OLDEST_FIRST',
  DRAFT_NEWEST_FIRST: 'DRAFT_NEWEST_FIRST',
  DRAFT_OLDEST_FIRST: 'DRAFT_OLDEST_FIRST',
  IN_SIGNING_NEWEST_FIRST: 'IN_SIGNING_NEWEST_FIRST',
  IN_SIGNING_OLDEST_FIRST: 'IN_SIGNING_OLDEST_FIRST',
  NOT_ACTIVE_NEWEST_FIRST: 'NOT_ACTIVE_NEWEST_FIRST',
  NOT_ACTIVE_OLDEST_FIRST: 'NOT_ACTIVE_OLDEST_FIRST',
  DISABLED_NEWEST_FIRST: 'DISABLED_NEWEST_FIRST',
  DISABLED_OLDEST_FIRST: 'DISABLED_OLDEST_FIRST',
};

export const profileSourceIndications = {
  WEB: 'WEB',
  WEB_DIGIBROKER: 'WEB_DIGIBROKER',
  WEB_ADVERTISEMENT_INVITE: 'WEB_ADVERTISEMENT_INVITE',
  WEB_BY_OTHER: 'WEB_BY_OTHER',
};

export const AdvertisementSourceType = {
  /** @deprecated source type kv24 is deprecated. Still hold, because we have advertisements created from kv24. */
  KV24: 'KV24',
  WEB_DIGIBROKER: 'WEB_DIGIBROKER',
  WEB_ADVERTISEMENT_EDIT: 'WEB_ADVERTISEMENT_EDIT',
  WEB_COPY: 'WEB_COPY',
  APP: 'APP',
};

export const agreementSourceTypes = {
  WEB: 'WEB',
};

export const ComponentFlexSpacing = {
  NONE: 'none',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  FULL: 'full',
};

export const ComponentMaxWidth = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large',
  FULL_WIDTH: 'full_width',
};

export const BackgroundCheckResult = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  PENDING: 'PENDING',
};

export const candidacyStatusResults = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  PENDING: 'PENDING',
  IN_ANOTHER_ACTIVE_AGREEMENT: 'IN_ANOTHER_ACTIVE_AGREEMENT',
  RETRACTED: 'RETRACTED',
};

export const ComponentSize = {
  XS: 'xs',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const ComponentColor = {
  RED: 'red',
  YELLOW: 'yellow',
  GRAY: 'gray',
  GREEN: 'green',
  BLUE: 'blue',
  LIGHT_GRAY: 'light-gray',
  ORANGE: 'orange',
};

export const Alignment = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify',
};

export const verticalAlignment = {
  TOP: 'top',
  BOTTOM: 'bottom',
  CENTER: 'center',
};

export const storyblokDatasources = {
  NEWSLETTER_SEGMENTS: 'segments',
};

export const sessionFlowSteps = {
  LOGIN: 'LOGIN',
  FILL_PROFILE: 'FILL_PROFILE',
  COMPLETED: 'COMPLETED',
};

export const authProviders = {
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
  EMAIL: 'EMAIL',
};
export const redirectLinks = {
  TENANT_PROFILE_OFFERS: '/dashboard/tenant-profile-offers',
  TENANT_PROFILE_SETTINGS: '/dashboard/profile-settings-tenant',
  TENANT_PROFILE_CANDIDACIES: 'dashboard/tenant-profile-candidacies',
  LANDLORD_PROFILE_SETTINGS: '/dashboard/profile-settings-landlord',
  LANDLORD_PROPERTIES: '/dashboard/landlord-properties',
  LANDLORD_AGREEMENTS: '/dashboard/landlord-agreements',
  CASE_STUDIES: '/case-studies',
  INVITE: '/invite/form/',
  INVITE_AGREEMENT: '/invite/agreement/',
  ARTICLES: '/articles',
  LEGAL: '/legal',
  TENANTS: '/tenants',
  FIND_HOME: '/find-home',
  CONTACT: '/contact',
};

export const digibrokerSteps = {
  _START_: '_START_',
  DOES_ALREADY_HAVE_TENANT: 'DOES_ALREADY_HAVE_TENANT',
  SELECT_AGREEMENT_TYPE: 'SELECT_AGREEMENT_TYPE',
  START_AGREEMENT_FLOW: 'START_AGREEMENT_FLOW',
  SELECT_TYPE_OF_PROPERTY: 'SELECT_TYPE_OF_PROPERTY',
  SESSION_FLOW: 'SESSION_FLOW',
  SELECT_ADDRESS: 'SELECT_ADDRESS',
  SELECT_FLOORS: 'SELECT_FLOORS',
  SELECT_AREA: 'SELECT_AREA',
  SELECT_ROOMS: 'SELECT_ROOMS',
  SELECT_RENT_RATE: 'SELECT_RENT_RATE',
  VIEW_RENDIN_FEE_EXPLAINER: 'VIEW_RENDIN_FEE_EXPLAINER',
  SELECT_ADDITIONAL_DETAILS: 'SELECT_ADDITIONAL_DETAILS',
  SELECT_DESCRIPTION: 'SELECT_DESCRIPTION',
  SELECT_IMAGES: 'SELECT_IMAGES',
  PREVIEW_ADVERTISEMENT: 'PREVIEW_ADVERTISEMENT',
  SUBMIT_ADVERTISEMENT: 'SUBMIT_ADVERTISEMENT',
  SUBMIT_AGREEMENT: 'SUBMIT_AGREEMENT',
  ERROR: 'ERROR',
};

export const digibrokerTrackingEventNames = {
  _START_: 'Digibroker: Start flow',
  DOES_ALREADY_HAVE_TENANT: 'Digibroker: Choose Have or Have not a Tenant',
  SEARCH_TENANT: 'Digibroker: Search Tenant',
  HAS_TENANT: 'Digibroker: Has Tenant',
  SELECT_TYPE_OF_PROPERTY: 'Digibroker: Select Type of Property',
  SELECT_ADDRESS: 'Digibroker: Add Property Address',
  SELECT_FLOORS: 'Digibroker: Add Property Floors',
  SELECT_AREA: 'Digibroker: Add Property Area',
  SELECT_ADDITIONAL_DETAILS: 'Digibroker: Add Property Storage, Pets And Parking',
  SELECT_ROOMS: 'Digibroker: Add Property Rooms',
  SELECT_RENT_RATE: 'Digibroker: Add Price',
  SELECT_DESCRIPTION: 'Digibroker: Add Description',
  SELECT_IMAGES: 'Digibroker: Add Images Step',
  ADD_IMAGE: 'Digibroker: Add Image',
  REMOVE_IMAGE: 'Digibroker: Remove Image',
  PREVIEW_AD: 'Digibroker: Preview Ad',
  FINALIZE_AD: 'Digibroker: Finalize Ad',
  SELECT_AGREEMENT_TYPE: 'Digibroker: Select Agreement Type',
  SUBMIT_AGREEMENT: 'Digibroker: Submit Agreement Draft',
};

export const postCandidateResults = {
  ALREADY_CANDIDATE: 'ALREADY_CANDIDATE',
  DELETED_CANDIDATE: 'DELETED_CANDIDATE',
  OK: 'OK',
};

export const feedbackParents = {
  FACEBOOK: 'facebook',
  APP_STORE: 'app_store',
  GOOGLE: 'google',
  RENDIN: 'rendin',
};

export const additionalFeeType = {
  BROKER_FEE: 'BROKER_FEE',
  FIXED_UTILITIES: 'FIXED_UTILITIES',
  CONTRACT_FEE: 'CONTRACT_FEE',
  ADMINISTRATIVE_FEE: 'ADMINISTRATIVE_FEE',
};

export const webSourceType = {
  WEB_DIGIBROKER: 'WEB_DIGIBROKER',
  WEB_ADVERTISEMENT_EDIT: 'WEB_ADVERTISEMENT_EDIT',
  WEB_AGREEMENT: 'WEB_AGREEMENT',
};

export const additionalFeeInterval = {
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
  ONE_TIME: 'ONE_TIME',
};

export const workflow = {
  POLAND: 'PL',
  ESTONIA: 'EE',
};

export const servicesAndUtilitiesPartyType = {
  LANDLORD: 'LANDLORD',
  TENANT: 'TENANT',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
};

export const userRole = {
  LANDLORD: 'LANDLORD',
  TENANT: 'TENANT',
  NONE: null,
};

export const languageNames = {
  EE: 'Eesti',
  PL: 'Polski',
  EN: 'English',
  RU: 'Русский',
};

export const advertisementStatuses = {
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
  DRAFT: 'DRAFT',
  DISABLED: 'DISABLED',
};

export const storyblokLinkTypes = {
  STORY: 'story',
  URL: 'url',
};

export const notificationTypes = {
  NEW_CANDIDATE: 'new_candidate',
  FILL_PROFILE: 'fill_profile',
  ACTIVE_TERMINATION: 'active_termination',
  ACTIVE_PL_EXTRAORDINARY_TERMINATION: 'active_pl_extraordinary_termination',
};

export const agreementStatuses = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  READY_TO_SIGN: 'READY_TO_SIGN',
  PRE_SIGNING: 'PRE_SIGNING',
  SIGNED: 'SIGNED',
  ACTIVE: 'ACTIVE',
  FINISHED: 'FINISHED',
  ARCHIVED: 'ARCHIVED',
};

export const agreementTypes = {
  EE_BASIC: 'EE_BASIC',
  EE_LUMIKODUD: 'EE_LUMIKODUD',
  PL_NO: 'PL_NO',
  PL_NI: 'PL_NI',
  PL_RE: 'PL_RE',
  UNKNOWN: 'UNKNOWN',
};

export const agreementPLTypes = {
  PL_NO: 'PL_NO',
  PL_NI: 'PL_NI',
  PL_RE: 'PL_RE',
};

export const agreementContractTypes = {
  FIXED: 'FIXED',
  TERMLESS: 'TERMLESS',
};

export const emailLoginAuthMethods = {
  LINK: 'link',
  PIN: 'pin',
};

export const sharingModalTypes = {
  DESKTOP_SHARE_MODAL: 'desktopShareModal',
  MOBILE_NATIVE_SHARE: 'mobileNativeShare',
  DESKTOP_MODAL_LINK: 'link',
};

export const backgroundColors = {
  white: 'bg-white',
  'gray-light': 'bg-gray-50',
  gray: 'bg-gray-300',
  'peach-schnapps': 'bg-rendin-100',
  orange: 'bg-rendin-500',
  'peach-extra-light': 'bg-peach-100',
  'peach-light': 'bg-peach-200',
  peach: 'bg-peach-300',
  'violet-50': 'bg-violet-50',
  'r-gradient': 'bg-gradient-to-r from-peach-100 via-peach-200 to-rendin-100',
  'r-gradient-tr': 'bg-gradient-to-tr from-peach-100 via-peach-200 to-rendin-100',
  'r-gradient-from-blue-to-violet':
    'bg-gradient-to-r from-lightaquamarine to-vividorchid',
  'violet-25': 'bg-violet-25',
  'peach-gradient': 'peach-gradient',
  'violet-to-blue-gradient': 'violet-to-blue-gradient',
};

export const textColors = {
  white: 'text-white',
  'gray-light': 'text-gray-50',
  gray: 'text-gray-300',
  'peach-schnapps': 'text-rendin-100',
  orange: 'text-rendin-500',
  'peach-extra-light': 'text-peach-100',
  'peach-light': 'text-peach-200',
  peach: 'text-peach-300',
  'violet-50': 'text-violet-50',
  'violet-25': 'text-violet-25',
};

export const backgroundColorsHexValue = {
  white: tailwindConfig.theme.colors.white,
  'gray-light': tailwindConfig.theme.colors.gray['50'],
  gray: tailwindConfig.theme.colors.gray['300'],
  'peach-schnapps': tailwindConfig.theme.colors.rendin['100'],
  orange: tailwindConfig.theme.colors.rendin['500'],
  'peach-extra-light': tailwindConfig.theme.colors.peach['100'],
  'peach-light': tailwindConfig.theme.colors.peach['200'],
  peach: tailwindConfig.theme.colors.peach['300'],
};

export const borderColors = {
  WHITE: 'border-white',
  GRAY_300: 'border-gray-300',
  RENDIN_500: 'border-rendin-500',
};

export const agreementCategories = {
  PRIVATE: 'PRIVATE',
  BUSINESS: 'BUSINESS',
};

export const CookieKeys = {
  LEADS_GENERATOR: 'rendin-leads-widget',
  CIO_TEMP_ID: 'cio-temp-id',
};

export const LocalStorageKeys = {
  DIGIBROKER_CACHE: 'rendin-digibroker-cache',
};

export const NavigationDirection = {
  FORWARDS: 'FORWARDS',
  BACKWARDS: 'BACKWARDS',
};

export const DigibrokerSuccessModalType = {
  LISTING: 'LISTING',
  AGREEMENT: 'AGREEMENT',
};

export const LeadsGeneratorSources = {
  MODAL: 'modal',
  SECTION: 'section',
};

export const utilitiesAndServicesModalSteps = {
  SELECT_UTILITIES: 'SELECT_UTILITIES',
  UTILITIES_VIEW: 'UTILITIES_VIEW',
  DELETE_LIST_VIEW: 'DELETE_LIST_VIEW',
  ADD_SERVICE_VIEW: 'ADD_SERVICE_VIEW',
  EDIT_SERVICE_VIEW: 'EDIT_SERVICE_VIEW',
};

export const administrativeFeePaymentDetermination = {
  FIXED: 'FIXED',
  DYNAMIC: 'DYNAMIC',
};

export const administrativeFeePaymentPartyType = {
  LANDLORD: 'LANDLORD',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
  TENANT: 'TENANT',
};

export const sessionFlowTypes = {
  GENERIC: 'GENERIC',
  SIGN_UP: 'SIGN_UP',
  SIGN_IN: 'SIGN_IN',
};

export const administrativeFeeModalSteps = {
  ADD_FEE: 'ADD_FEE',
  EDIT_FEE: 'EDIT_FEE',
};

export const insuranceInvoiceTo = {
  TENANT: 'TENANT',
  LANDLORD: 'LANDLORD',
};

export const insuranceInvoicePaymentDay = {
  PAYMENT_DAY: '15',
};

export const isoLanguageCode = {
  et: 'et-EE',
  pl: 'pl-PL',
  en: 'en-GB',
  ru: 'ru-RU',
};

export const sharingModalNetworksForAgreement = ['whatsapp', 'email', 'SMS'];

export const PredictionProvider = {
  GOOGLE: 'GOOGLE',
  MAAAMET: 'MAAAMET',
};

export const oldHandoverStatuses = {
  DRAFT: 'DRAFT',
  COMPLETE: 'COMPLETE',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
};

export const handoverStatuses = {
  DRAFT: 'DRAFT',
  READY_TO_SEND: 'READY_TO_SEND',
  COMPLETED: 'COMPLETED',
  FINISHED: 'FINISHED',
};

export const handoverStatusesOrder = [
  handoverStatuses.DRAFT,
  handoverStatuses.READY_TO_SEND,
  handoverStatuses.COMPLETED,
  handoverStatuses.FINISHED,
];

export const handoverCompletedStatuses = [
  handoverStatuses.COMPLETED,
  handoverStatuses.FINISHED,
  oldHandoverStatuses.COMPLETE,
  oldHandoverStatuses.ACCEPTED,
  oldHandoverStatuses.DECLINED,
];

export const agreementSignedStatuses = [
  agreementStatuses.SIGNED,
  agreementStatuses.ACTIVE,
  agreementStatuses.FINISHED,
  agreementStatuses.ARCHIVED,
];

export const handoverEntryTypes = {
  HANDOVER: 'HANDOVER',
  ROOM: 'ROOM',
  METER: 'METER',
  OTHER: 'OTHER',
  FEEDBACK: 'FEEDBACK',
};

export const handoverDataTypes = {
  METERS: 'meters',
  ROOMS: 'rooms',
  OTHERS: 'others',
  FEEDBACK: 'feedback',
  CONDITION: 'condition',
  CLEANING: 'cleaning',
};

export const handoverDataMeterTypes = {
  COLD_WATER: 'COLD_WATER',
  HOT_WATER: 'HOT_WATER',
  ELECTRICITY_DAY: 'ELECTRICITY_DAY',
  ELECTRICITY_NIGHT: 'ELECTRICITY_NIGHT',
  GAS: 'GAS',
  GENERAL_ELECTRICITY: 'GENERAL_ELECTRICITY',
  OTHER: 'OTHER',
};

export const handoverDataRoomTypes = {
  LIVING_ROOM: 'LIVING_ROOM',
  BEDROOM: 'BEDROOM',
  KITCHEN: 'KITCHEN',
  BATHROOM: 'BATHROOM',
  WC: 'WC',
  BALCONY: 'BALCONY',
  STORAGE: 'STORAGE',
  OTHER: 'OTHER',
};

export const handoverDataOthersTypes = {
  VALUABLES: 'VALUABLES',
  KEYS: 'KEYS',
  PAYMENTS: 'PAYMENTS',
  DEFECT: 'DEFECT',
  DOCUMENT: 'DOCUMENT',
  OTHER: 'OTHER',
};

export const handoverEditViewActiveSteps = {
  START: 0,
  CONDITION: 1,
  CLEANING: 2,
  METERS: 3,
  ROOMS: 4,
  OTHERS: 5,
  CLOSURE: 6,
};

export const handoverEntityValidationErrors = {
  MISSING_ENTITY: 'MISSING_ENTITY',
  MISSING_ENTITY_FILES: 'MISSING_ENTITY_FILES',
};

export const annexStatus = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  IN_SIGNING: 'IN_SIGNING',
  IN_PROCESSING: 'IN_PROCESSING',
};

export const terminationStatus = {
  IN_PROCESSING: 'IN_PROCESSING',
  IN_SIGNING: 'IN_SIGNING',
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  IN_REVIEW: 'IN_REVIEW',
};

export const terminationTypes = {
  PL_ANNEX: 'PL_ANNEX',
  PL_EXTRAORDINARY: 'PL_EXTRAORDINARY',
  CONTACT_CS: 'CONTACT_CS',
};

export const terminationViewSteps = {
  START: 'START',
  TYPE: 'TYPE',
  MUTUAL_NEW_DATE: 'MUTUAL_NEW_DATE',
  EXTRAORDINARY_REASONS: 'EXTRAORDINARY_REASONS',
  EXTRAORDINARY_DETAILS: 'EXTRAORDINARY_DETAILS',
  EXTRAORDINARY_RESELECT_TYPE: 'EXTRAORDINARY_RESELECT_TYPE',
  PREVIEW: 'PREVIEW',
};

export const newProfileTypeByOther = {
  COTENANT: 'COTENANT',
  LANDLORD: 'LANDLORD',
};

export const inviteSourceTypes = {
  AGREEMENT: 'AGREEMENT',
  ADVERTISEMENT: 'ADVERTISEMENT',
};

export const censorshipTriggersKeys = {
  DEPOSIT: 'DEPOSIT',
  SHORT_TERM: 'SHORT_TERM',
};

export const adPropertyTypes = {
  APARTMENT: 'APARTMENT',
  HOUSE: 'HOUSE',
  ROOM: 'ROOM',
};

export const advertisementEditViewTabs = {
  INFO: 'info',
  PRICE: 'price',
  DESCRIPTION: 'description',
  IMAGES: 'images',
};

export const buttonActions = {
  OPEN_MODAL: 'OPEN_MODAL',
  REDIRECT_TO_LINK: 'REDIRECT_TO_LINK',
};

export const linkTypes = {
  URL: 'url',
  STORY: 'story',
};
