<template>
  <div v-if="!isTenantView" class="agreement-alerts flex flex-col gap-2 mb-4">
    <!--    #3 Agreement ending and renewal for PL-->
    <r-alert
      v-if="agreementTerminationChangeInSigningPl"
      :primary-button-label="
        $t('agreement.activity_cards.termination_change_in_signing.button')
      "
      :title="$t('agreement.activity_cards.termination_change_in_signing.title')"
      :type="alertTypes.INFORMATIVE"
      :secondary-button-label="$t('buttons.cancel_signing')"
      @click-primary-button="$emit('clickOpenTerminationChange')"
      @click-secondary-button="$emit('clickCancelTermination')"
    >
      <div
        class="whitespace-break-spaces"
        v-html="
          $t('agreement.activity_cards.termination_change_in_signing.content', {
            expirationDate: constructDateAndTimeFromIsoString(
              agreementTerminations?.pending?.expirationDate,
              agreementTimeZone,
            ),
            agreementEndDate: constructDateFromIsoString(
              agreementFromFirebase?.endDate,
              agreementTimeZone,
            ),
          })
        "
      ></div>
    </r-alert>
    <r-alert
      v-if="agreementTerminationInSigningPl"
      :primary-button-label="
        $t('agreement.activity_cards.termination_in_signing.button')
      "
      :title="$t('agreement.activity_cards.termination_in_signing.title')"
      :type="alertTypes.INFORMATIVE"
      :secondary-button-label="$t('buttons.cancel_signing')"
      @click-primary-button="$emit('clickOpenTermination')"
      @click-secondary-button="$emit('clickCancelTermination')"
    >
      <div
        class="whitespace-break-spaces"
        v-html="
          $t('agreement.activity_cards.termination_in_signing.content', {
            expirationDate: constructDateAndTimeFromIsoString(
              agreementTerminations?.pending?.expirationDate,
              agreementTimeZone,
            ),
          })
        "
      ></div>
    </r-alert>

    <r-alert
      v-if="agreementExtraordinaryTerminationInReviewPl"
      :title="$t('agreement.activity_cards.termination_extraordinary_in_review.title')"
      :type="alertTypes.INFORMATIVE"
    >
      <div>
        {{
          $t('agreement.activity_cards.termination_extraordinary_in_review.content_1')
        }}
      </div>
      <div class="text-black font-medium my-2">
        {{
          $t('agreement.activity_cards.termination_extraordinary_in_review.subtitle')
        }}
      </div>
      <div>
        {{
          $t('agreement.activity_cards.termination_extraordinary_in_review.content_2')
        }}
      </div>
    </r-alert>
    <r-alert
      v-if="agreementTerminationActivePl"
      :title="$t('agreement.activity_cards.termination_reminder.title')"
      :type="alertTypes.WARNING"
    >
      <div
        class="whitespace-break-spaces"
        v-html="
          $t('agreement.activity_cards.termination_reminder.content', {
            terminationDate: constructDateFromIsoString(
              agreementFromFirebase.endDate,
              agreementTimeZone,
            ),
          })
        "
      ></div>
    </r-alert>

    <r-alert
      v-if="agreementTerminatedEE"
      :title="$t('agreement.activity_cards.termination.title')"
      :type="alertTypes.WARNING"
    >
      <div>
        {{ $t('agreement.activity_cards.termination.content') }}
        <b>{{
          constructDateFromIsoString(
            agreementFromFirebase.terminationDate,
            agreementTimeZone,
          )
        }}</b>
      </div>
    </r-alert>

    <r-alert
      v-if="agreementEndsSoonPl"
      :primary-button-label="
        $t('agreement.activity_cards.agreement_end_soon_pl.button')
      "
      :title="$t('agreement.activity_cards.agreement_end_soon_pl.title')"
      :type="alertTypes.INFORMATIVE"
      @click-primary-button="onClickOpenRenewal"
    >
      <div>
        {{
          $t('agreement.activity_cards.agreement_end_soon_pl.content', {
            endDate: constructDateFromIsoString(
              agreementFromFirebase.endDate,
              agreementTimeZone,
            ),
          })
        }}
      </div>
    </r-alert>

    <r-alert
      v-if="agreementAnnexInSigningPl"
      :primary-button-label="$t('agreement.activity_cards.annex_in_signing.button')"
      :title="$t('agreement.activity_cards.annex_in_signing.title')"
      :type="alertTypes.INFORMATIVE"
      @click-primary-button="onClickOpenRenewal(annexInSigning.id)"
    >
      <div
        class="whitespace-break-spaces"
        v-html="
          $t('agreement.activity_cards.annex_in_signing.content', {
            annexSignDate: datePlus3Days(annexInSigning?.created),
          })
        "
      ></div>
    </r-alert>

    <r-alert
      v-if="agreementAnnexSignedPl"
      :title="$t('agreement.activity_cards.annex_signed.title')"
      :type="alertTypes.CONFIRMATIVE"
    >
      <div>
        {{
          $t('agreement.activity_cards.annex_signed.content', {
            endDate: constructDateFromIsoString(
              agreementFromFirebase.endDate,
              agreementTimeZone,
            ),
          })
        }}
      </div>
    </r-alert>

    <r-alert
      v-if="agreementFinalHandoverReminderPl"
      :title="$t('agreement.activity_cards.final_handover_reminder.title')"
      :type="alertTypes.WARNING"
      :primary-button-label="
        $t('agreement.activity_cards.final_handover_reminder.button')
      "
      @click-primary-button="$emit('clickLinkToFinalHandover')"
    >
      <div>
        {{ $t('agreement.activity_cards.final_handover_reminder.content') }}
      </div>
    </r-alert>

    <!--    #2 Handover accepted with or without feedback PL and EE -->
    <r-alert
      v-if="handoverConfirmedWithFeedback"
      :primary-button-label="
        $t('agreement.activity_cards.handover_has_feedback.button')
      "
      :title="$t('agreement.activity_cards.handover_has_feedback.title')"
      :type="alertTypes.CONFIRMATIVE"
      @click-primary-button="onClickOpenHandoverProtocol"
    >
      <div>{{ $t('agreement.activity_cards.handover_has_feedback.content') }}</div>
    </r-alert>
    <r-alert
      v-if="handoverConfirmed"
      :title="$t('agreement.activity_cards.handover_done.title')"
      :type="alertTypes.CONFIRMATIVE"
    >
      <div>{{ $t('agreement.activity_cards.handover_done.content') }}</div>
    </r-alert>

    <!--    #1 Should clear the mess with agreement statuses but should be calculated server side-->
    <r-alert
      v-if="agreementSignedButNotActiveEE"
      :title="$t('agreement.activity_cards.not_active_yet.title')"
      :type="alertTypes.INFORMATIVE"
    >
      <div>{{ $t('agreement.activity_cards.not_active_yet.content') }}</div>

      <div class="mt-4">
        {{ $t('agreement.activity_cards.not_active_yet.content_data') }}
        <b>{{
          constructDateFromIsoString(agreementFromFirebase.startDate, agreementTimeZone)
        }}</b>
      </div>
    </r-alert>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import { alertTypes } from '~/components/r-interface-components/utils/constants';
import {
  constructDateAndTimeFromIsoString,
  constructDateFromIsoString,
} from '~/utils/dateFromIsoStringConstructor.ts';
import { annexStatus, handoverStatuses, terminationStatus } from '~/utils/constants';

export default {
  name: 'AgreementAlerts',

  props: {
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    agreementAnnexes: {
      type: Array,
      default: null,
    },
    agreementTerminations: {
      type: Object,
      default: null,
    },
    isTenantView: {
      type: Boolean,
      default: true,
    },
    isAgreementTypePl: {
      type: Boolean,
      default: false,
    },
    agreementTimeZone: {
      type: String,
      default: null,
    },
  },

  emits: [
    'clickOpenHandoverProtocol',
    'clickOpenRenewal',
    'clickOpenTermination',
    'clickCancelTermination',
    'clickOpenTerminationChange',
    'clickLinkToFinalHandover',
  ],

  setup() {
    return {
      constructDateFromIsoString,
      constructDateAndTimeFromIsoString,
    };
  },

  data() {
    return {
      alertTypes,
      handoverStatuses,
    };
  },

  computed: {
    isAgreementStatusActive() {
      return (
        this.agreementFromFirebase.status === agreementStatuses.ACTIVE ||
        this.agreementFromFirebase.status === agreementStatuses.SIGNED
      );
    },

    // #1 is EE,
    agreementSignedButNotActiveEE() {
      // TODO Kadri - front expecting status from server side
      return false;
    },
    // #2 handover accepted
    handoverConfirmedWithFeedback() {
      return (
        this.agreementHandover?.handover?.status === handoverStatuses.FINISHED &&
        this.agreementHandover?.feedback &&
        this.isDeadlineDateInRange(this.agreementHandover?.handover?.updated, 7, false)
      );
    },
    handoverConfirmed() {
      return (
        this.agreementHandover?.handover?.status === handoverStatuses.FINISHED &&
        !this.agreementHandover?.feedback &&
        this.isDeadlineDateInRange(this.agreementHandover?.handover?.updated, 7, false)
      );
    },

    // #3 agreement termination
    agreementTerminatedEE() {
      return (
        !this.isAgreementTypePl &&
        this.isDeadlineDateInRange(this.agreementFromFirebase?.terminationDate, 7, true)
      );
    },

    // Pl, 90 days before and no annexes in for current termination date
    // hide if termination exist
    agreementEndsSoonPl() {
      return (
        this.isAgreementTypePl &&
        this.isDeadlineDateInRange(this.agreementFromFirebase?.endDate, 90, true) &&
        !this.hasAnnexForCurrentAgreementEndDate &&
        !(this.agreementTerminations?.active || this.agreementTerminations?.pending)
      );
    },
    // PL has annex in signing
    agreementAnnexInSigningPl() {
      return this.isAgreementTypePl && !!this.annexInSigning;
    },
    // PL has termination change in signing
    agreementTerminationChangeInSigningPl() {
      return (
        this.isAgreementTypePl &&
        this.isAgreementStatusActive &&
        this.agreementTerminations?.pending?.status === terminationStatus.IN_SIGNING &&
        !!this.agreementTerminations?.active
      );
    },
    // PL has extraordinary termination in review
    agreementExtraordinaryTerminationInReviewPl() {
      return (
        this.isAgreementTypePl &&
        this.isAgreementStatusActive &&
        this.agreementTerminations?.pending?.status === terminationStatus.IN_REVIEW &&
        this.agreementTerminations?.pending?.type === terminationTypes.PL_EXTRAORDINARY
      );
    },
    // PL has termination in signing
    agreementTerminationInSigningPl() {
      return (
        this.isAgreementTypePl &&
        this.isAgreementStatusActive &&
        this.agreementTerminations?.pending?.status === terminationStatus.IN_SIGNING &&
        !this.agreementTerminations?.active
      );
    },
    //PL Agreement will be terminated
    agreementTerminationActivePl() {
      return (
        this.isAgreementTypePl &&
        this.isAgreementStatusActive &&
        !!this.agreementTerminations?.active &&
        !this.agreementTerminations?.pending &&
        this.isDeadlineDateInRange(this.agreementFromFirebase?.endDate, 43, true)
      );
    },
    // PL final handover reminder
    agreementFinalHandoverReminderPl() {
      return (
        this.isAgreementTypePl &&
        this.isAgreementStatusActive &&
        this.isDeadlineDateInRange(this.agreementFromFirebase?.endDate, 43, true)
      );
    },

    agreementAnnexSignedPl() {
      return (
        this.isAgreementTypePl &&
        !!this.agreementAnnexes?.find(
          (annex) =>
            annex?.status === annexStatus.ACTIVE &&
            this.isDeadlineDateInRange(annex?.fileSigned?.created, 7, false),
        )
      );
    },

    hasAnnexForCurrentAgreementEndDate() {
      return this.agreementAnnexes?.some(
        (annex) =>
          new Date(annex.new.endDate) > new Date(this.agreementFromFirebase?.endDate),
      );
    },

    annexInSigning() {
      return this.agreementAnnexes?.find(
        (annex) => annex?.status === annexStatus.IN_SIGNING,
      );
    },
  },

  methods: {
    datePlus3Days(isoDate) {
      const date = DateTime.fromISO(isoDate).setZone('Europe/Warsaw').startOf('day');
      const deadlineDate = date.plus({ days: 3 });

      return isoDate ? this.constructDateFromIsoString(deadlineDate) : '';
    },

    isDeadlineDateInRange(deadlineDateISO, numberOfDays, beforeDeadline) {
      const deadlineDate = DateTime.fromISO(deadlineDateISO);
      const today = DateTime.now().setZone('Europe/Warsaw');
      let startDate, endDate;

      if (beforeDeadline) {
        startDate = deadlineDate.minus({ days: numberOfDays });
        endDate = deadlineDate;
      } else {
        startDate = deadlineDate;
        endDate = deadlineDate.plus({ days: numberOfDays });
      }

      return today >= startDate && today <= endDate;
    },

    onClickOpenHandoverProtocol() {
      this.$emit('clickOpenHandoverProtocol');
    },
    onClickOpenRenewal(annexId) {
      this.$emit('clickOpenRenewal', annexId);
    },
  },
};
</script>
